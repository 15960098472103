<template>
  <v-container>
    <v-dialog v-model="dialog" persistent max-width="600px" :scrollable="$vuetify.breakpoint.xsOnly"
      :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-form v-model="valid" ref="itemForm" @submit="item_submit">
        <v-card tile height="100%">
          <v-card-title class="pa-0">
            <v-app-bar>
              <v-app-bar-title>Cuenta de acceso</v-app-bar-title>
              <v-spacer></v-spacer>
              <v-btn x-small color="info" fab @click="close()">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-app-bar>
          </v-card-title>
          <v-card-text class="pt-3">
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field readonly v-model="item.user_name" id="user_name" :rules="f_required" label="Nombre"
                  hide-details="auto" required dense outlined @keyup="returnCap"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field readonly v-model="item.user_email" id="address" :rules="f_required"
                  label="Correo Electrónico" hide-details="auto" required dense outlined @keyup="returnCap">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="item.user_password" label="Contraseña" ref="user_password"
                  :error="errors.user_password" :append-icon="password_value ? 'mdi-eye-outline' : 'mdi-eye'"
                  @click:append="() => (password_value = !password_value)" :type="password_value ? 'password' : 'text'"
                  hide-details="auto" dense outlined required />
              </v-col>
              <v-col cols="6" md="6">
                <v-select v-model="item.user_access" ref="user_access" :error="errors.user_access"
                  :items="access_levels" item-text="text" item-value="value" label="Acceso" outlined dense
                  hide-details="auto" :rules="f_required" required />
              </v-col>
              <v-col cols="6" md="6">
                <v-select v-model="item.store_id" ref="store_id" :error="errors.store_id" :items="storeLst"
                  item-text="name" item-value="code" label="Tienda" outlined dense hide-details="auto"
                  :rules="f_required" />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pa-0">
            <v-app-bar>
              <v-btn :disabled="newItem" large text color="error" type="button" @click="delete_itm(item)">
                Borrar
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn large text color="info" type="submit">
                Guardar
              </v-btn>
            </v-app-bar>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog persistent max-width="290" v-model="dialog_confirm">
      <v-card>
        <v-card-title> Confirmar </v-card-title>
        <v-card-text> Desea eliminar el registro? </v-card-text>
        <v-card-actions>
          <v-btn text color="red" type="button" @click="dialog_confirm = !dialog_confirm">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="success" type="button" @click="delete_item">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";
import createItem from "../utils/createUser.js";
export default {
  name: "PartyForm",
  components: {},
  data() {
    return {
      password_value: String,
      loading_status: false,
      tab: null,
      errors: createItem(),
      dialog_confirm: false,
      compressed_image: null,
      reg_delete: null,
      valid: false,
      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      codeRules: [
        (v) => !!v || "El código es requerido",
        (v) => v.length === 4 || "EL código debe tener 4 caracteres.",
      ],
      type_id: [
        { value: "13", text: "Cédula de ciudadanía" },
        { value: "31", text: "NIT" },
        { value: "11", text: "Registro civil" },
        { value: "12", text: "Tarjeta de identidad" },
        { value: "21", text: "Tarjeta de extranjería" },
        { value: "22", text: "Cédula de extranjería" },
        { value: "41", text: "Pasaporte" },
        { value: "42", text: "Documento de identificación extranjero" },
        { value: "50", text: "NIT de otro país" },
      ],
      typeaccountsID: [
        { value: "1", text: "Persona Juridica" },
        { value: "2", text: "Persona Natural" },
      ],
      TaxLevelCodes: [
        { value: "04", text: "Simple" },
        { value: "05", text: "Ordinario" },
      ],
      access_levels: [
        { value: 'Owner', text: 'Propietario' },
        { value: 'Admin', text: 'Administrador' },
        { value: 'Sales', text: 'Ventas' },
        { value: 'Orders', text: 'Compras' },
        { value: 'Trout', text: 'Trucha' },
        { value: 'Promotor', text: 'Promotor' },
        { value: 'Supervisor', text: 'Supervisor' },
        { value: 'Digitador', text: 'Digitador' },
        { value: 'Chef', text: 'Chef' },
        { value: 'QR4', text: 'QR4' },
        { value: 'Providers', text: 'Proveedores' },
        { value: 'Supplier', text: 'Mayorista'},
      ],
      storeLst: [],
    };
  },
  props: {
    table: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
      default: createItem(),
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    newItem: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() {
    this.get_stores();
  },
  methods: {
    returnCap(event) {
      this.item[event.target.id] = event.target.value.toUpperCase();
    },
    onlyNumber($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    getImagePhoto: function (account, src) {
      var url = "https://sw67.ca/photos/" + account + "/" + src;
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    chooseImage() {
      this.$refs.fileInput.click();
    },
    onSelectFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          var img = new Image();
          img.onload = () => {
            var prct = 800 / img.width;
            const elem = document.createElement("canvas");
            elem.width = img.width * prct;
            elem.height = img.height * prct;
            const ctx = elem.getContext("2d");
            ctx.drawImage(img, 0, 0, img.width * prct, img.height * prct);
            var imageUrl = elem.toDataURL("image/jpeg");
            this.compressed_image = imageUrl;
            //this.$emit("compressed-image", imageUrl);
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.item.picture = files[0].name.toLowerCase();
        //this.$emit("input", files[0].name.toLowerCase());
      }
    },
    item_submit(e) {
      e.preventDefault();
      //this.valid = this.$refs.itemForm.validate();
      var md5 = require("md5");
      this.errors.store_id = !this.$refs.store_id.validate();
      this.errors.user_access = !this.$refs.user_access.validate();
      this.errors.user_password = !this.$refs.user_password.validate();
      console.log(this.item);
      this.item.user_password = md5(this.item.user_password);
      if (this.valid) {
        var qry = {
          account: this.$store.state.profile.account,
          table: "users",
          data: [this.item],
        };
        if (this.compressed_image) {
          qry.photo = this.compressed_image;
        }
        webserver("put_table", qry, () => {
          this.compressed_image = null;
          this.loading_status = false;
          this.$emit("refresh", true);
        });
      }
    },
    delete_itm(data) {
      this.reg_delete = data.code;
      this.dialog_confirm = true;
    },
    delete_item() {
      var record = this.reg_delete;
      this.loading_status = true;
      var qry = {
        table: this.table,
        filters: {
          account: this.$store.state.profile.account,
          code: record,
        },
      };
      webserver("delete_record", qry, () => {
        this.reg_delete = null;
        this.dialog_confirm = false;
        this.loading_status = false;
        this.$emit("refresh", true);
      });
    },
    close() {
      this.$emit("close", true);
    },
    call_contact(data) {
      window.location.href = "tel://" + data;
    },
    get_stores() {
      var qry = {
        account: this.$store.state.profile.account,
        table: "stores",
        filters: [],
      };
      webserver("get_table", qry, (data) => {
        this.storeLst = data.sort((a, b) => {
          if (a.name < b.name)
            return -1;
          if (a.name > b.name)
            return 1;
          return 0;
        });
        console.log(this.storeLst);
      });
    },
  },
};
</script>

<style>
.base-image-input {
  display: block;
  width: 100%;
  height: 250px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

.placeholder:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}
</style>